import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function AboutPage() {
  return (
    <Layout>
      <SEO
        title="About"
        keywords={[`RBII`, `John Blanchard`, `apps`, `privacy`, `standard`, `permissions`]}
      />

      <section className="text-center">
        
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block p-3">
            Privacy
          </h2>

        <div style={{height:15}}/>

        <p className="leading-loose">
            When an app asks for permission, accept it with caution.
            However if you're using one of the underlying apps, I can promise you that I only access your photos to do limited actions on your behalf.
            I can also promise you that I only use the camera when you're expecting it.
            Contact information is only used in app when it's necessary.
            App permission is not an invitation to abuse user's privacy.
        </p>

        <div style={{height:8}}/>

        <p className="leading-loose">
            Downloading an app forfeits your rights to privacy.
            Most apps use your data to create a better app.
            At no point will any of my apps collect internal user data.
            In my apps you will remain anonymous, none of your app sessions are collected.
        </p>

      </section>

    </Layout>
  );
}

export default AboutPage;
